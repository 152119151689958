//= Functions & Modules
// Others
import React from 'react';

type Props = {
    dialogClassName?: string;
    onClose: () => void;
};

export class Dialog extends React.PureComponent<Props> {
    static defaultProps = {
        dialogClassName: '',
    };

    render() {
        return (
            <div
                className="w-screen h-screen fixed left-0 right-0 flex justify-center items-center"
                style={{ backgroundColor: 'rgba(33, 33, 33, 0.5)' }}
                onClick={(event) => {
                    if (event.target == event.currentTarget) {
                        this.props.onClose();
                    }
                }}
            >
                <div className={`${this.props.dialogClassName}`}>{this.props.children}</div>
            </div>
        );
    }
}
