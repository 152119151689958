//= Functions & Modules
// Others
import React, { useContext } from 'react';
import classNames from 'classnames';

//= Structures & Data
// Own
import { StatisticProps } from '../data/StatisticProps';
import { ThemeContext } from '../../theme';

export function Statistic({ label, className, labelClassName, contentClassName, children }: StatisticProps) {
    const { statisticTheme, statisticLabelTheme, statisticContentTheme } = useContext(ThemeContext);

    const classes = classNames(statisticTheme, className);
    const labelClasses = classNames(statisticLabelTheme, labelClassName);
    const contentClasses = classNames(statisticContentTheme, contentClassName);

    return (
        <div className={classes}>
            <div className={labelClasses}>{label}</div>
            <div className={contentClasses}>{children}</div>
        </div>
    );
}
