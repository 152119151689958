//= Functions & Modules
// Own
import { getGlobalClient } from '../utils/getGlobalClient';
// Others
import React from 'react';
import { boundMethod } from 'autobind-decorator';

//= React components
// Own
import { ConnectionStatusListener } from './ConnectionStatusListener';
import { NoConnection } from './NoConnection';

type Props = {
    onConnected: () => void;
};

type State = {
    connectedToServer: boolean;
};

export class OnlyWhenConnected extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            connectedToServer: getGlobalClient().getStatus(),
        };
    }

    @boundMethod
    onConnectionChange(status: boolean) {
        this.setState({
            connectedToServer: status,
        });

        if (status) this.props.onConnected();
    }

    render() {
        return (
            <ConnectionStatusListener onConnectionChange={this.onConnectionChange}>
                {this.state.connectedToServer ? this.props.children : <NoConnection />}
            </ConnectionStatusListener>
        );
    }
}
