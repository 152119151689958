//= Functions & Modules
// Others
import { createContext } from 'react';

//= Structures & Data
// Own
import { ThemeContextData } from './ThemeContextData';
import { DefaultTheme } from './DefaultTheme';

export const ThemeContext = createContext<ThemeContextData>(DefaultTheme);
