//= Structures & Data
// Own
import { ButtonProps } from './data/ButtonProps';

//= React components
// Own
import { Button } from './components/Button';

//export { ButtonProps };
export { Button };

export default {
    Button,
};
