//= Functions & Modules
// Others
import { boundMethod } from 'autobind-decorator';
import React from 'react';

//= Structures & Data
// Own
import { ShowConnectDialogEventName } from '../data/ShowConnectDialogEventName';
import { ConnectStatusChangedEventName } from '../data/ConnectStatusChangedEventName';

type State = {
    connected: boolean;
};

export class ConnectionStatus extends React.PureComponent<{}, State> {
    constructor(props: {}) {
        super(props);

        this.state = {
            connected: false,
        };
    }

    componentDidMount() {
        window.addEventListener(ConnectStatusChangedEventName, this.onConnectStatusChanged.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener(ConnectStatusChangedEventName, this.onConnectStatusChanged.bind(this));
    }

    @boundMethod
    onConnectStatusChanged(event: CustomEvent) {
        this.setState({ connected: event.detail });
    }

    @boundMethod
    showConnectDialog() {
        window.dispatchEvent(new CustomEvent(ShowConnectDialogEventName));
    }

    render() {
        return (
            <div className="flex items-center text-sm" role="button" onClick={this.showConnectDialog}>
                <div className={`rounded-full w-4 h-4 mr-1 ${this.state.connected ? 'bg-green-500' : 'bg-red-500'}`}></div>
                <div>{this.state.connected ? 'Connected' : 'Not connected'}</div>
            </div>
        );
    }
}
