//= Functions & Modules
// Others
//import Cookies from 'universal-cookie';
import ReactDOM from 'react-dom';
import React from 'react';

//= React components
// Own
import { App } from './App';
// Others
import { BrowserRouter } from 'react-router-dom';

//const cookies = new Cookies();
//const accessToken = cookies.get('token');

//if (accessToken) {
ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('main')
);
//} else {
//    ReactDOM.render(<AccessTokenDialog />, document.getElementById('main'));
//}
