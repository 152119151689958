//= Functions & Modules
// Others
import { boundMethod } from 'autobind-decorator';
import React, { Suspense } from 'react';
import { nanoid } from 'nanoid/non-secure';
import immer from 'immer';

type State = {
    dialogIDs: string[];
};

export class DialogsSystem extends React.PureComponent<{}, State> {
    private dialogRenderFunctions: { [key: string]: () => React.ReactElement };

    constructor(props: {}) {
        super(props);

        this.dialogRenderFunctions = {};

        this.state = {
            dialogIDs: [],
        };
    }

    @boundMethod
    onClose(dialogID: string) {
        this.setState(
            immer(this.state, (draftState) => {
                draftState.dialogIDs.splice(draftState.dialogIDs.indexOf(dialogID), 1);
            }),
            () => {
                this.dialogRenderFunctions[dialogID] = undefined;
            }
        );
    }

    @boundMethod
    showDialog(
        importFunc: () => Promise<{ default: React.ComponentType<any> }>,
        props: { [key: string]: any } = {},
        onClose?: (...args: any[]) => void
    ) {
        const id = nanoid();
        const Component = React.lazy(importFunc);
        this.dialogRenderFunctions[id] = () => (
            <Component
                {...props}
                onClose={(...args: any[]) => {
                    if (onClose) onClose(...args);
                    this.onClose(id);
                }}
            />
        );

        this.setState(
            immer(this.state, (draftState) => {
                draftState.dialogIDs.push(id);
            })
        );
    }

    @boundMethod
    showDialogWithResponse<T = any>(
        importFunc: () => Promise<{ default: React.ComponentType<any> }>,
        props: { [key: string]: any } = {}
    ): Promise<T> {
        return new Promise<T>((resolve) => this.showDialog(importFunc, props, resolve));
    }

    render() {
        return (
            <div className="absolute">
                {this.state.dialogIDs.map((dialogID) => {
                    return (
                        <Suspense key={dialogID} fallback={<div>Loading...</div>}>
                            {this.dialogRenderFunctions[dialogID]()}
                        </Suspense>
                    );
                })}
            </div>
        );
    }
}
