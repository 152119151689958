//= Functions & Modules
// Others
import React, { useContext } from 'react';
import classNames from 'classnames';

//= Structures & Data
// Own
import { SimpleCardProps } from '../data/SimpleCardProps';
import { ThemeContext } from '../../theme';

const InternalSimpleCard: React.ForwardRefRenderFunction<unknown, SimpleCardProps> = (props, ref) => {
    const { simpleCardTheme } = useContext(ThemeContext);

    const { className, ...otherProps } = props;

    const classes = classNames(simpleCardTheme, className);

    return (
        <div {...otherProps} className={classes} ref={ref as any}>
            {props.children}
        </div>
    );
};

const SimpleCard = React.forwardRef<unknown, SimpleCardProps>(InternalSimpleCard);
SimpleCard.displayName = 'SimpleCard';

export { SimpleCard };
