//= Functions & Modules
// Others
import React, { useContext } from 'react';
import classNames from 'classnames';

//= Structures & Data
// Own
import { TabsMenuItemProps } from '../data/TabsMenuItemProps';
import { ThemeContext } from '../../theme';

export function TabsMenuItem(props: TabsMenuItemProps) {
    const { tabsMenuItemTheme } = useContext(ThemeContext);
    const classes = classNames(tabsMenuItemTheme, props.className);

    return (
        <div
            role="button"
            className={classes}
            onClick={() => {
                console.log('TabsMenuItem:click');
                props.onClick();
            }}
        >
            {props.children}
        </div>
    );
}
