//= Functions & Modules
// Others
import React from 'react';

//= React components
// Others

type Props = React.HTMLProps<HTMLSelectElement>;

export class Select extends React.PureComponent<Props> {
    render() {
        const { className, ...otherProps } = this.props;
        return (
            <select {...otherProps} className={`input ${className ? className : ''}`}>
                {this.props.children}
            </select>
        );
    }
}
