//= Functions & Modules
// Own
import { getDialogsSystem } from './getDialogsSystem';

//= Structures & Data
// Own
import { Props } from '../components/GenericSingleInputDialog';

export async function createGenericSingleInputDialog<T>(props: Omit<Props, 'onClose'>): Promise<T> {
    return new Promise((resolve) => getDialogsSystem().showDialog(() => import('../components/GenericSingleInputDialog'), props, resolve));
}
