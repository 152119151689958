//= Functions & Modules
// Others
import React from 'react';

type Props = React.HTMLProps<HTMLDivElement>;

export class Card extends React.PureComponent<Props> {
    render() {
        const { className, ...otherProps } = this.props;
        return (
            <div {...otherProps} className={`p-2 bg-panel rounded-lg shadow-sm ${className ? className : ''}`}>
                {this.props.children}
            </div>
        );
    }
}
