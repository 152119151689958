//= Functions & Modules
// Others
import React from 'react';

//= React components
// Others
import { boundMethod } from 'autobind-decorator';

type Props = {
    inDialog?: boolean;
    className?: string;
};

type State = {
    openMenu: boolean;
};

export class Menu extends React.PureComponent<Props, State> {
    static defaultProps = {
        inDialog: false,
        className: '',
    };

    constructor(props: Props) {
        super(props);

        this.state = { openMenu: false };
    }

    @boundMethod
    setOpenNavbar(status: boolean) {
        this.setState({ openMenu: status });
    }

    render() {
        return (
            <div
                className={`${
                    this.props.inDialog == false ? 'hidden md:!flex' : 'flex'
                } w-[15rem] flex-col space-y-0.5 bg-normal px-5 py-5`}
            >
                {this.props.children}
            </div>
        );
    }
}
