//= Functions & Modules
// Others
import React from 'react';

//= React components
// Own
import { ConnectionStatus } from '../modules/server';

type Props = {
    className?: string;
};

export class Bottom extends React.PureComponent<Props> {
    static defaultProps = {
        className: '',
    };

    render() {
        return (
            <div className={`flex justify-between bg-normal px-1 py-0.5 ${this.props.className}`}>
                <div>
                    <ConnectionStatus />
                </div>
                <div className="text-sm">Created by Softprovider</div>
            </div>
        );
    }
}
