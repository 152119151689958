//= Functions & Modules
// Own
import { Client, getGlobalClient, setGlobalClient } from './modules/server';
import { setDialogsSystem } from './modules/dialogs';
import { useConstructor } from './utils/useConstructor';
// Others
import React, { useEffect, useRef, useState } from 'react';

//= Structures & Data
// Own
import { URLs } from './data/URLs';
import { ShowConnectDialogEventName } from './modules/server';

//= React components
// Own
import { Bottom } from './components/Bottom';
import Top from './components/Top';
import AppMenu from './components/AppMenu';
import { DialogsSystem } from './modules/dialogs';
import { NotificationsSystem, setNotificationsSystem } from './modules/notifications';
// Others
import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import XALUI from '@softprovider/xalui';

//= Style & Assets
// Own
import './styles/global.scss';
import '@material-tailwind/react/tailwind.css';

const ProgramMakerPage = React.lazy(() => import('./modules/program_maker'));
const BotsPage = React.lazy(() => import('./modules/bots/pages/BotsPage'));
const BotPage = React.lazy(() => import('./modules/bots/modules/bot/pages/BotPage'));
const BinanceAccountsListPage = React.lazy(() =>
    import('./modules/binance_accounts/pages/BinanceAccountsList').then((module) => ({ default: module.BinanceAccountsList }))
);
const TradingProgramsListPage = React.lazy(() => import('./modules/trading_programs/pages/TradingProgramsList'));

export function App() {
    const dialogsSystemRef = useRef<DialogsSystem>();
    const notificationsSystemRef = useRef<NotificationsSystem>();

    const connectToServerWithRefreshToken = async () => {
        const client = getGlobalClient();

        try {
            const connected = await client.connect();

            if (connected) await client.loginWithRefreshToken();
        } catch (error) {
            console.error(error);
        }

        console.log('DONE');
    };

    const showConnectDialog = () => {
        dialogsSystemRef.current.showDialog(() => import('./modules/server/components/ConnectDialog'));
    };

    useConstructor(() => {
        setGlobalClient(new Client());
        connectToServerWithRefreshToken();
    });

    useEffect(() => {
        setDialogsSystem(dialogsSystemRef.current);
        setNotificationsSystem(notificationsSystemRef.current);
        window.addEventListener(ShowConnectDialogEventName, showConnectDialog);

        return () => {
            window.removeEventListener(ShowConnectDialogEventName, showConnectDialog);
        };
    }, []);

    return (
        <XALUI.ThemeProvider>
            <div className="flex flex-col w-full h-full">
                <Top />
                <div className="flex flex-1 overflow-y-hidden">
                    <AppMenu />
                    <div className="flex-1 w-full p-3 overflow-y-auto">
                        <Suspense fallback={<div>Loading</div>}>
                            <Switch>
                                <Route exact path={URLs.PROGRAM_MAKER} component={ProgramMakerPage} />
                                <Route exact path={URLs.BOTS} component={BotsPage} />
                                <Route exact path={URLs.BOT} component={BotPage} />
                                <Route exact path={URLs.BINANCE_ACCOUNTS} component={BinanceAccountsListPage} />
                                <Route exact path={URLs.TRADING_PROGRAMS} component={TradingProgramsListPage} />
                            </Switch>
                        </Suspense>
                    </div>
                </div>
                <Bottom className="w-full flex-shrink-0" />
                <DialogsSystem ref={dialogsSystemRef} />
                <NotificationsSystem ref={notificationsSystemRef} />
            </div>
        </XALUI.ThemeProvider>
    );
}
