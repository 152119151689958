//= Structures & Data
// Own
import { SimpleCardProps } from './data/SimpleCardProps';

//= React components
// Own
import { SimpleCard } from './components/SimpleCard';

//export { SimpleCardProps };
export { SimpleCard };

export default {
    SimpleCard,
};
