//= Functions & Modules
// Others
import React from 'react';

//= React components
// Own
import { Background } from './Background';
import { Dialog } from './Dialog';
import { Button, Input } from '../../theme';

export type Props = {
    title: string;
    label?: string;
    doneButtonLabel: string;
    onClose: (value: any) => void;
    inputProps: React.HTMLProps<HTMLInputElement>;
};

type State = {
    value: any;
};

export default class GenericSingleInputDialog extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: '',
        };
    }

    render() {
        return (
            <Background onClose={() => this.props.onClose(null)}>
                <Dialog title={this.props.title} className="w-2/5" onClose={() => this.props.onClose(null)}>
                    <form
                        className="flex flex-col space-y-2 my-2 p-3"
                        onSubmit={(event) => {
                            event.preventDefault();
                            this.props.onClose(this.state.value);
                        }}
                    >
                        {this.props.label != null && <div>{this.props.label}</div>}
                        <Input
                            {...this.props.inputProps}
                            value={this.state.value}
                            onChange={(event) => this.setState({ value: event.currentTarget.value })}
                        />
                        <Button className="self-center">{this.props.doneButtonLabel}</Button>
                    </form>
                </Dialog>
            </Background>
        );
    }
}
