//= Structures & Data
// Own
import { ThemeContextData } from './ThemeContextData';

export const DefaultTheme: ThemeContextData = {
    buttonTheme: 'p-2 rounded bg-primary color-primary',
    simpleCardTheme: 'p-simpleCard border-simpleCard bg-simpleCard',
    tabsMenuTheme: 'bg-tabsMenu',
    tabsVerticalMenuTheme: 'flex',
    tabsMenuItemTheme: 'p-2',
    tabsContextTheme: 'p-2 bg-tabsContext',
    statisticTheme: 'p-2 flex flex-col',
    statisticLabelTheme: 'text-statisticLabel',
    statisticContentTheme: '',
};
