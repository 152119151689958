//= Functions & Modules
// Others
import React from 'react';

//= React components
// Others

type Props = React.HTMLProps<HTMLInputElement>;

export class Input extends React.PureComponent<Props> {
    render() {
        const { className, ...otherProps } = this.props;
        return <input {...otherProps} className={`input ${className ? className : ''}`} />;
    }
}
