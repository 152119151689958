//= Functions & Modules
// Own
import { getDialogsSystem } from '../modules/dialogs';
// Others
import React from 'react';

//= React components
// Own
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { boundMethod } from 'autobind-decorator';

type Props = {
    className?: string;
};

export default class Top extends React.PureComponent<Props> {
    static defaultProps = {
        className: '',
    };

    @boundMethod
    openMenu() {
        getDialogsSystem().showDialog(() => import('./AppMenuDialog'));
    }

    render() {
        return (
            <div className={`flex justify-between items-center bg-normal px-5 py-1 h-10 ${this.props.className}`}>
                <div role="button" className="block md:!hidden" onClick={this.openMenu}>
                    <FontAwesomeIcon icon={faBars} size="lg" />
                </div>
            </div>
        );
    }
}
