//= Functions & Modules
// Others
import React from 'react';

type Props = {
    label: string;
    labelWidth?: number;
};

export class InputRow extends React.PureComponent<Props> {
    render() {
        return (
            <div className="flex space-x-2 items-center">
                <div className="pt-1" style={{ width: `${this.props.labelWidth}px` }}>
                    {this.props.label}
                </div>
                {this.props.children}
            </div>
        );
    }
}
