//= Functions & Modules
// Others
import React from 'react';

type Props = React.HTMLProps<HTMLFormElement> & {
    labelWidth: number;
};

export class Form extends React.PureComponent<Props> {
    render() {
        const children = React.Children.map(this.props.children, (child) => {
            if (child.type.name == 'InputRow') {
                return React.cloneElement(child as any, { labelWidth: this.props.labelWidth });
            } else {
                return child;
            }
        });

        return <form {...this.props}>{children}</form>;
    }
}
