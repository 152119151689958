//= Functions & Modules
// Others
import { useRef } from 'react';

export function useConstructor(callback: () => void) {
    const hasBeenCalled = useRef<boolean>(false);
    if (hasBeenCalled.current) return;

    callback();
    hasBeenCalled.current = true;
}
