//= Functions & Modules
// Others
import React, { useContext } from 'react';
import classNames from 'classnames';

//= Structures & Data
// Own
import { TabsLayoutType } from '../data/TabsLayoutType';
import { TabsMenuDisplayName } from '../data/TabsMenuDisplayName';
import { TabsLayoutProps } from '../data/TabsLayoutProps';
import { ThemeContext } from '../../theme';
import { TabsMenuProps } from '../data/TabsMenuProps';

const TabsVerticalLayout = (props: TabsLayoutProps) => {
    const { tabsVerticalMenuTheme } = useContext(ThemeContext);

    const Children = React.Children.map(props.children, (child: React.ReactElement) => {
        if (child.type.displayName == TabsMenuDisplayName) {
            return React.cloneElement<TabsMenuProps>(child, { className: classNames(tabsVerticalMenuTheme, child.props.className) });
        } else {
            return child;
        }
    });

    return <div className="flex flex-col">{Children}</div>;
};

TabsVerticalLayout.displayName = 'TabsVerticalLayout';

export { TabsVerticalLayout };
