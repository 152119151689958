//= Functions & Modules
// Others
import React from 'react';

//= Structures & Data
// Own
import { URLs } from '../data/URLs';

//= React components
// Own
import { Menu, MenuOption } from '../modules/menu';

type Props = {
    inDialog?: boolean;
    className?: string;
};

export default class AppMenu extends React.PureComponent<Props> {
    render() {
        return (
            <Menu {...this.props}>
                <MenuOption label="BOTS" href={URLs.BOTS} />
                <MenuOption label="PROG MAKER" href={URLs.PROGRAM_MAKER} />
                <MenuOption label="BINANCE ACCOUNTS" href={URLs.BINANCE_ACCOUNTS} />
                <MenuOption label="trading programs" href={URLs.TRADING_PROGRAMS} />
            </Menu>
        );
    }
}
