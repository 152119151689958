//= Functions & Modules
// Others
import React from 'react';

//= React components
// Others
import ThemeButton from '@material-tailwind/react/Button';

type Props = React.HTMLProps<HTMLButtonElement> & {
    size?: string;
};

export class Button extends React.PureComponent<Props> {
    static defaultProps = {
        size: 'regular',
    };

    render() {
        return (
            <ThemeButton color="lightBlue" buttonType="filled" ripple="light" {...this.props}>
                {this.props.children}
            </ThemeButton>
        );
    }
}
