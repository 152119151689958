//= Functions & Modules
// Others
import React, { useContext } from 'react';
import classNames from 'classnames';

//= Structures & Data
// Own
import { TabsMenuProps } from '../data/TabsMenuProps';
import { TabsMenuDisplayName } from '../data/TabsMenuDisplayName';
import { TabsMenuItemProps } from '../data/TabsMenuItemProps';
import { ThemeContext } from '../../theme';

function TabsMenu(props: TabsMenuProps) {
    const { className, currentItem } = props;

    const { tabsMenuTheme } = useContext(ThemeContext);
    const classes = classNames(tabsMenuTheme, className);

    const Children = React.Children.map(props.children, (child) => {
        const childProps: Partial<TabsMenuItemProps> = {
            onClick: () => {
                console.log('TabsMenu:ItemClicked', child.props.id);
                props.onItemClicked(child.props.id);
            },
        };

        if (child.props.id == currentItem) {
            childProps.active = true;
        }

        return React.cloneElement(child, childProps);
    });

    return <div className={classes}>{Children}</div>;
}

TabsMenu.displayName = TabsMenuDisplayName;

export { TabsMenu };
