//= Functions & Modules
// Others
import React from 'react';

type Props = {
    onClose: () => void;
};

export class Background extends React.PureComponent<Props> {
    render() {
        return (
            <div
                className="fixed w-screen h-screen top-0 left-0 flex items-center justify-center z-[9000]"
                style={{ backgroundColor: 'rgba(255,255,255,.16)' }}
                onClick={(event) => {
                    if (event.currentTarget == event.target) {
                        this.props.onClose();
                    }
                }}
            >
                {this.props.children}
            </div>
        );
    }
}
