//= React components
// Own
import { Tabs } from './components/Tabs';
import { TabsContext } from './components/TabsContext';
import { TabsItem } from './components/TabsItem';
import { TabsMenu } from './components/TabsMenu';
import { TabsMenuItem } from './components/TabsMenuItem';
import { TabsVerticalLayout } from './components/TabsVerticalLayout';
import { VerticalTabs } from './components/VerticalTabs';

export { Tabs, TabsContext, TabsItem, TabsMenu, TabsMenuItem, TabsVerticalLayout, VerticalTabs };

export default {
    Tabs,
    TabsContext,
    TabsItem,
    TabsMenu,
    TabsMenuItem,
    TabsVerticalLayout,
    VerticalTabs,
};
