//= Functions & Modules
// Others
import React from 'react';

//= Structures & Data
// Own
import { TabsProps } from '../data/TabsProps';

//= React components
// Own
import { Tabs } from './Tabs';
import { TabsVerticalLayout } from './TabsVerticalLayout';

type Props = Omit<TabsProps, 'layout'>;

const InternalVerticalTabs: React.ForwardRefRenderFunction<unknown, Props> = (props, ref) => {
    const { children, ...otherProps } = props;

    return (
        <Tabs ref={ref} layout={TabsVerticalLayout} {...otherProps}>
            {children}
        </Tabs>
    );
};

const VerticalTabs = React.forwardRef<unknown, Props>(InternalVerticalTabs);
Tabs.displayName = 'VerticalTabs';

export { VerticalTabs };
