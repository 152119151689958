//= Functions & Modules
// Own
import { getGlobalClient } from './getGlobalClient';

//= Structures & Data
// Own
import { ConnectStatusChangedEventName } from '../data/ConnectStatusChangedEventName';

export async function waitUntilAbleToCallCommands(): Promise<void> {
    const client = getGlobalClient();

    if (client.getStatus()) return;

    return new Promise((resolve) => {
        const onConnection = (event: CustomEvent) => {
            if (event.detail) {
                resolve();
                window.removeEventListener(ConnectStatusChangedEventName, onConnection);
            }
        };

        window.addEventListener(ConnectStatusChangedEventName, onConnection);
    });
}
