//= Functions & Modules
// Others
import React from 'react';

type Props = {
    title: string;
    onClose: () => void;
    className?: string;
    error?: boolean;
};

export class Dialog extends React.PureComponent<Props> {
    static defaultProps = {
        className: '',
    };

    render() {
        return (
            <div className={`flex flex-col rounded bg-light shadow-sm ${this.props.className}`}>
                <div className={`flex p-2 ${this.props.error ? 'bg-red-500' : 'bg-blue-500'} text-gray-100 rounded-t`}>
                    <div>{this.props.title}</div>
                </div>
                {this.props.children}
            </div>
        );
    }
}
