//= Functions & Modules
// Own
import { useConstructor } from '../../../utils/useConstructor';
// Others
import React, { useEffect } from 'react';

//= Structures & Data
// Own
import { ConnectStatusChangedEventName } from '../data/ConnectStatusChangedEventName';

type Props = React.PropsWithChildren<{
    onConnectionChange: (status: boolean) => void;
}>;

export function ConnectionStatusListener(props: Props) {
    const onConnection = (event: CustomEvent) => {
        props.onConnectionChange(event.detail);
    };

    useConstructor(() => {
        window.addEventListener(ConnectStatusChangedEventName, onConnection);
    });

    useEffect(() => {
        return () => window.removeEventListener(ConnectStatusChangedEventName, onConnection);
    });

    return <>{props.children}</>;
}
