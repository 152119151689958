//= Functions & Modules
// Others
import React, { Suspense, useEffect, useState } from 'react';
import { TabsItemType } from '../data/TabsItemType';
import { TabsMenuItemType } from '../data/TabsMenuItemType';

//= Structures & Data
// Own
import { TabsProps } from '../data/TabsProps';

//= React components
// Own
import { TabsContext } from './TabsContext';
import { TabsMenu } from './TabsMenu';
import { TabsMenuItem } from './TabsMenuItem';

const InternalTabs: React.ForwardRefRenderFunction<unknown, TabsProps> = (props, ref) => {
    const Layout = props.layout;
    const [currentItem, setCurrentItem] = useState<string | number>(props.defaultTab || null);

    const MenuItems: TabsMenuItemType[] = [];
    let CurrentComponent: React.ReactElement | React.ExoticComponent;

    React.Children.forEach(props.children, (child: TabsItemType) => {
        MenuItems.push(
            <TabsMenuItem key={child.props.id} id={child.props.id}>
                {child.props.label}
            </TabsMenuItem>
        );

        if (child.props.id == currentItem) {
            if (props.fallback === true) {
                CurrentComponent = <Suspense fallback={<div>Loading...</div>}>{child.props.renderComponent()}</Suspense>;
            } else if (props.fallback) {
                CurrentComponent = <Suspense fallback={props.fallback}>{child.props.renderComponent()}</Suspense>;
            } else {
                CurrentComponent = child.props.renderComponent();
            }
        }
    });

    useEffect(() => {
        React.Children.forEach(props.children, (child: TabsItemType) => {
            if (child.props.id == currentItem) {
                if (props.fallback === true) {
                    CurrentComponent = <Suspense fallback={<div>Loading...</div>}>{child.props.renderComponent()}</Suspense>;
                } else if (props.fallback) {
                    CurrentComponent = <Suspense fallback={props.fallback}>{child.props.renderComponent()}</Suspense>;
                } else {
                    CurrentComponent = child.props.renderComponent();
                }
            }
        });
    }, [currentItem]);

    return (
        <Layout>
            <TabsMenu
                currentItem={currentItem}
                onItemClicked={(id) => {
                    console.log('NEW ITEM', id);
                    setCurrentItem(id);
                }}
            >
                {MenuItems}
            </TabsMenu>
            <TabsContext>{CurrentComponent}</TabsContext>
        </Layout>
    );
};

const Tabs = React.forwardRef<unknown, TabsProps>(InternalTabs);
Tabs.displayName = 'Tabs';

export { Tabs };
