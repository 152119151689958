//= Functions & Modules
// Others
import React, { useContext } from 'react';
import classNames from 'classnames';

//= Structures & Data
// Own
import { ButtonProps } from '../data/ButtonProps';
import { ThemeContext } from '../../theme';

const InternalButton: React.ForwardRefRenderFunction<unknown, ButtonProps> = (props, ref) => {
    const { buttonTheme } = useContext(ThemeContext);

    const { className, ...otherProps } = props;

    const classes = classNames(buttonTheme, className);

    return (
        <button {...otherProps} className={classes} ref={ref as any}>
            {props.children}
        </button>
    );
};

const Button = React.forwardRef<unknown, ButtonProps>(InternalButton);
Button.displayName = 'Button';

export { Button };
