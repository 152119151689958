export enum Command {
    LOGIN_WITH_AUTH_TOKEN,
    LOGIN_WITH_REFRESH_TOKEN,
    REFRESH_TOKEN,

    SUBSCRIBE_TO_TRADING_BOT,
    UNSUBSCRIBE_FROM_TRADING_BOT,
    CHECK_IF_IS_TRADING_BOT_ALIVE,
    CHECK_IF_IS_TRADING_BOT_RUNNING,
    START_BOT_PROGRAM,
    STOP_BOT_PROGRAM,
    FORCE_STOP_BOT_PROGRAM,
    SET_BOT_PROGRAM,
    SET_BOT_PROGRAM_GLOBAL_VARIABLE,
    SET_BOT_BINANCE_ACCOUNT,
    SET_BOT_SYMBOL_ID,
    GET_TRADING_BOT_ALL_GLOBAL_VARIABLES,
    SET_TRADING_BOT_GLOBAL_VARIABLE,
    GET_TRADING_BOT_ORDERS,
    GET_TRADING_BOT_CURRENT_LOGS,

    GET_SYMBOLS,

    EVENT_LOGIN_SUCCESS,
    EVENT_LOGIN_ERROR,
    EVENT_REFRESH_TOKEN,
    EVENT_IS_TRADING_BOT_ALIVE,
    EVENT_IS_TRADING_BOT_RUNNING,
    EVENT_BOT_ORDER,
    EVENT_TRADING_BOT_NEW_PRICE_TICK,
    EVENT_TRADING_BOT_USED_WEIGHT,
    EVENT_TRADING_BOT_SETTED_GLOBAL_VARIABLE,
    EVENT_TRADING_BOT_ALL_GLOBAL_VARIABLES,
    EVENT_TRADING_BOT_PLACED_ORDER,
    EVENT_TRADING_BOT_ORDER_STATUS_CHANGED,
    EVENT_TRADING_BOT_LOG,
    EVENT_PONG,

    GET_TRADING_BOTS_LIST,

    GET_BINANCE_ACCOUNTS_LIST,
    ADD_BINANCE_ACCOUNT,
    DELETE_BINANCE_ACCOUNT,

    GET_TRADING_PROGRAMS_LIST,
    ADD_TRADING_PROGRAM,
    UPDATE_TRADING_PROGRAM,
    DELETE_TRADING_PROGRAM,
    TEST_TRADING_PROGRAM,

    GET_TRADING_BOT_REPORTS_PER_HOUR,
    GET_TRADING_BOT_REPORTS_PER_DAY,
    GET_TRADING_BOT_REPORTS_PER_WEEK,

    PING,

    _TOTAL,
}
