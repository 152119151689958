//= Functions & Modules
// Others
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { nanoid } from 'nanoid/non-secure';
import immer from 'immer';
import { NotificationType } from '../data/NotificationType';

type State = {
    notificationIDs: string[];
};

export class NotificationsSystem extends React.PureComponent<{}, State> {
    private notificationsData: { [key: string]: { type: NotificationType; message: string } };

    constructor(props: {}) {
        super(props);

        this.notificationsData = {};

        this.state = {
            notificationIDs: [],
        };
    }

    @boundMethod
    onClose(notificationID: string) {
        this.setState(
            immer(this.state, (draftState) => {
                draftState.notificationIDs.splice(draftState.notificationIDs.indexOf(notificationID), 1);
            }),
            () => {
                this.notificationsData[notificationID] = undefined;
            }
        );
    }

    @boundMethod
    addNotification(type: NotificationType, message: string) {
        const id = nanoid();

        this.notificationsData[id] = { type, message };

        this.setState(
            immer(this.state, (draftState) => {
                draftState.notificationIDs.push(id);
            }),
            () => {
                window.setTimeout(() => this.onClose(id), 5000);
            }
        );
    }

    render() {
        return (
            <div className="fixed top-2 right-2 flex flex-col space-y-2">
                {this.state.notificationIDs.map((notificationID) => {
                    const data = this.notificationsData[notificationID];
                    return (
                        <div
                            key={notificationID}
                            className={`w-96 p-2 rounded ${data.type == NotificationType.SUCCESS ? 'bg-green-100' : 'bg-red-100'}`}
                        >
                            {data.message}
                        </div>
                    );
                })}
            </div>
        );
    }
}
