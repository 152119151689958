//= Functions & Modules
// Others
import React from 'react';

//= React components
// Others
import { NavLink } from 'react-router-dom';

type Props = {
    label: string;
    href: string;
};

export class MenuOption extends React.PureComponent<Props> {
    render() {
        return (
            <NavLink
                to={this.props.href}
                className={(isActive: boolean) =>
                    `block px-3.5 py-3 text-sm ${
                        isActive ? 'text-selected bg-selected rounded-lg selected-menu-option' : 'text-unselected1'
                    }`
                }
            >
                {this.props.label}
            </NavLink>
        );
    }
}
