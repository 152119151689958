//= Structures & Data
// Own
import { ThemeContext, ThemeProvider } from './theme';

//= React components
// Own
import Buttons from './buttons';
import Cards from './cards';
import Statistics from './statistics';
import Tabs from './tabs';

export default {
    Button: Buttons.Button,
    SimpleCard: Cards.SimpleCard,
    Statistic: Statistics.Statistic,
    VerticalTabs: Tabs.VerticalTabs,
    TabsItem: Tabs.TabsItem,
    ThemeContext,
    ThemeProvider,
    Buttons,
    Cards,
    Statistics,
    Tabs,
};
