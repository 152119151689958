//= Functions & Modules
// Others
import React from 'react';

//= Structures & Data
// Own
import { ThemeContextData } from './ThemeContextData';
import { DefaultTheme } from './DefaultTheme';
import { ThemeContext } from './ThemeContext';

type Props = React.PropsWithChildren<{ theme?: ThemeContextData }>;

export function ThemeProvider({ children, theme }: Props) {
    return <ThemeContext.Provider value={theme || DefaultTheme}>{children}</ThemeContext.Provider>;
}
