//= Functions & Modules
// Others
import React, { useContext } from 'react';
import classNames from 'classnames';

//= Structures & Data
// Own
import { TabsContextProps } from '../data/TabsContextProps';
import { ThemeContext } from '../../theme';

const TabsContext = (props: TabsContextProps) => {
    const { tabsContextTheme } = useContext(ThemeContext);
    const classes = classNames(tabsContextTheme, props.className);

    return <div className={classes}>{props.children}</div>;
};

TabsContext.displayName = 'TabsContext';

export { TabsContext };
