//= Functions & Modules
// Others
import React from 'react';

type Props = React.HTMLProps<HTMLTextAreaElement>;

export default class TextArea extends React.PureComponent<Props> {
    render() {
        const { className, ...otherProps } = this.props;
        return (
            <textarea {...otherProps} className={`input ${className ? className : ''}`}>
                {this.props.children}
            </textarea>
        );
    }
}
