//= Functions & Modules
// Others
import { nanoid } from 'nanoid';
import Cookie from 'universal-cookie';

//= Structures & Data
// Own
import { Command } from '../data/Command';
import { ConnectStatusChangedEventName } from '../data/ConnectStatusChangedEventName';
import { CookieAddress } from '../data/CookieAddress';
import { NeedToAuthEventName } from '../data/NeedToAuthEventName';
import { ServerError } from '../data/ServerError';

const COOKIE_REFRESH_TOKEN = 'x-refresh-token';

export class Client {
    private websocket: WebSocket;
    private status: boolean;
    private messagesCallbackIDs: { [key: string]: any };
    private actualRefreshToken: string;
    private autoRefreshIntervalID: any;
    private pingIntervalID: any;
    private defaultServerAddress: string;
    private defaultServerAdressLoadingPromise: Promise<void>;

    private newMessagesListeners: { name: string; listener: (message: any) => void }[];

    constructor() {
        this.websocket = null;
        this.status = false;
        this.messagesCallbackIDs = {};
        this.newMessagesListeners = [];

        this.defaultServerAdressLoadingPromise = this.loadDefaultAddressServer();
    }

    private async reconnectOnDisconnect() {
        let connected: boolean = false;
        while (!connected) {
            connected = await this.connect();
        }

        const result = await this.loginWithRefreshToken();

        if (!result) {
            this.websocket.removeEventListener('message', this.onMessage.bind(this));
            this.websocket.removeEventListener('close', this.onClose.bind(this));
        }
    }

    private onClose() {
        console.log('onClose socket');
        window.dispatchEvent(new CustomEvent(ConnectStatusChangedEventName, { detail: false }));

        window.clearInterval(this.pingIntervalID);
        window.clearInterval(this.autoRefreshIntervalID);

        this.status = false;

        this.reconnectOnDisconnect();
    }

    private onMessage(event: MessageEvent) {
        //console.log('onMessage', event.data);
        const message = JSON.parse(event.data);

        for (let i = 0, length = this.newMessagesListeners.length; i < length; ++i) {
            if (this.newMessagesListeners[i]) this.newMessagesListeners[i].listener(message);
        }

        if (message.error) {
            if (message.error == ServerError.NEED_AUTH) {
                window.dispatchEvent(new CustomEvent(NeedToAuthEventName));
            }
        }

        const id = message.id;
        if (id && this.messagesCallbackIDs[id]) {
            this.messagesCallbackIDs[id](message);
            this.messagesCallbackIDs[id] = undefined;
        }
    }

    private sendCommand(command: Command, data?: { [key: string]: any }, callback?: (data: any) => void) {
        let id: string;
        if (callback) {
            id = nanoid();
            this.messagesCallbackIDs[id] = callback;
        }

        const message: any = {
            c: command,
        };

        if (data) Object.assign(message, data);
        if (id) message.id = id;

        console.log('Sending command:', message);
        this.websocket.send(JSON.stringify(message));
    }

    private async refreshToken(): Promise<void> {
        return new Promise((resolve) => {
            const listenerName = 'refreshToken';
            this.subscribeToNewMessages(listenerName, (message: any) => {
                if (message.c == Command.EVENT_REFRESH_TOKEN) {
                    console.log('NEW REFRESH TOKEN', message.refreshToken);
                    this.actualRefreshToken = message.refreshToken;

                    const cookie = new Cookie();
                    cookie.set(COOKIE_REFRESH_TOKEN, this.actualRefreshToken, { secure: true, path: '/', maxAge: 5 * 60 * 1000 });
                    resolve();
                    this.unsubscribeFromNewMessages(listenerName);
                }
            });

            this.sendCommand(Command.REFRESH_TOKEN, null);
        });
    }

    private onLogged() {
        this.status = true;
        window.dispatchEvent(new CustomEvent(ConnectStatusChangedEventName, { detail: true }));
        this.refreshToken();

        this.autoRefreshIntervalID = window.setInterval(this.refreshToken.bind(this), 2.5 * 60 * 1000);
        this.pingIntervalID = window.setInterval(this.ping.bind(this), 30 * 1000);
    }

    private async loadDefaultAddressServer() {
        const a = await fetch('/ws.txt');
        this.defaultServerAddress = await a.text();
        console.log('loadDefaultAddressServer', this.defaultServerAddress);
    }

    public subscribeToNewMessages(name: string, listener: (message: any) => void) {
        this.newMessagesListeners.push({ name, listener });
    }

    public unsubscribeFromNewMessages(name: string) {
        for (let i = 0, length = this.newMessagesListeners.length; i < length; ++i) {
            if (this.newMessagesListeners[i].name == name) {
                this.newMessagesListeners.splice(i, 1);
                break;
            }
        }
    }

    public getStatus(): boolean {
        return this.status;
    }

    public async connect(address?: string): Promise<boolean> {
        if (!address) {
            if (this.defaultServerAddress) {
                address = this.defaultServerAddress;
            } else if (this.defaultServerAdressLoadingPromise) {
                await this.defaultServerAdressLoadingPromise;
                address = this.defaultServerAddress;
            }

            if (!this.defaultServerAddress) {
                const cookie = new Cookie();
                address = cookie.get(CookieAddress);
            }

            if (!address) throw new Error();
        }

        return new Promise((resolve, reject) => {
            this.websocket = new WebSocket(address);

            const onOpen = () => {
                resolve(true);

                const cookie = new Cookie();
                cookie.set(CookieAddress, address, { path: '/', secure: true });

                this.websocket.removeEventListener('open', onOpen);
                this.websocket.addEventListener('message', this.onMessage.bind(this));
                this.websocket.addEventListener('close', this.onClose.bind(this));
            };

            this.websocket.addEventListener('open', onOpen);
            this.websocket.addEventListener('error', (error) => {
                console.error(error);
                resolve(false);
            });
        });
    }

    public async loginWithAuthToken(token: string): Promise<boolean> {
        return new Promise((resolve) => {
            const listenerName = 'loginWithAuthToken';
            this.subscribeToNewMessages(listenerName, (message: any) => {
                console.log('WEE', message.c, Command.EVENT_LOGIN_SUCCESS);
                if (message.c == Command.EVENT_LOGIN_SUCCESS) {
                    this.onLogged();
                    resolve(true);
                } else if (message.c == Command.EVENT_LOGIN_ERROR) {
                    resolve(false);
                }

                this.unsubscribeFromNewMessages(listenerName);
            });

            this.sendCommand(Command.LOGIN_WITH_AUTH_TOKEN, { token });
        });
    }

    public async loginWithRefreshToken(): Promise<boolean> {
        if (!this.actualRefreshToken) {
            const cookie = new Cookie();
            this.actualRefreshToken = cookie.get(COOKIE_REFRESH_TOKEN);

            if (!this.actualRefreshToken) return false;
        }

        return new Promise((resolve) => {
            const listenerName = 'loginWithRefreshToken';
            this.subscribeToNewMessages(listenerName, (message: any) => {
                console.log('SAVE', message, Command.EVENT_LOGIN_SUCCESS, Command.EVENT_LOGIN_ERROR);
                if (message.c == Command.EVENT_LOGIN_SUCCESS) {
                    this.onLogged();
                    resolve(true);
                } else if (message.c == Command.EVENT_LOGIN_ERROR) {
                    resolve(false);
                }

                this.unsubscribeFromNewMessages(listenerName);
            });

            this.sendCommand(Command.LOGIN_WITH_REFRESH_TOKEN, { token: this.actualRefreshToken });
        });
    }

    public async getTradingBotsList(): Promise<any> {
        return new Promise((resolve, reject) =>
            this.sendCommand(Command.GET_TRADING_BOTS_LIST, null, (message) => {
                if (message.error) {
                    reject(message.error);
                    return;
                }

                resolve(message.items);
            })
        );
    }

    public async setBotBinanceAccount(tradingBotID: string, binanceAccountID: string, encryptionKey: string) {
        return new Promise((resolve) => {
            this.sendCommand(Command.SET_BOT_BINANCE_ACCOUNT, { tradingBotID, binanceAccountID, encryptionKey }, resolve);
        });
    }

    public async setBotTradingProgram(tradingBotID: string, tradingProgramID: string) {
        return new Promise((resolve) => {
            this.sendCommand(Command.SET_BOT_PROGRAM, { tradingBotID, tradingProgramID }, resolve);
        });
    }

    public async setBotSymbolID(tradingBotID: string, symbolID: string) {
        return new Promise((resolve) => {
            this.sendCommand(Command.SET_BOT_SYMBOL_ID, { tradingBotID, symbolID }, resolve);
        });
    }

    public async startTradingProgram(tradingBotID: string) {
        this.sendCommand(Command.START_BOT_PROGRAM, { tradingBotID });
    }

    public async stopTradingProgram(tradingBotID: string) {
        this.sendCommand(Command.STOP_BOT_PROGRAM, { tradingBotID });
    }

    public subscribeToTradingBot(tradingBotID: string) {
        this.sendCommand(Command.SUBSCRIBE_TO_TRADING_BOT, { tradingBotID });
    }

    public unsubscribeFromTradingBot(tradingBotID: string) {
        this.sendCommand(Command.UNSUBSCRIBE_FROM_TRADING_BOT, { tradingBotID });
    }

    public isTradingBotRunning(tradingBotID: string) {
        this.sendCommand(Command.CHECK_IF_IS_TRADING_BOT_RUNNING, { tradingBotID });
    }

    public isTradingBotAlive(tradingBotID: string) {
        this.sendCommand(Command.CHECK_IF_IS_TRADING_BOT_ALIVE, { tradingBotID });
    }

    public getTradingBotAllGlobalVariables(tradingBotID: string) {
        this.sendCommand(Command.GET_TRADING_BOT_ALL_GLOBAL_VARIABLES, { tradingBotID });
    }

    public getBinanceAccountsList() {
        this.sendCommand(Command.GET_BINANCE_ACCOUNTS_LIST);
    }

    public addBinanceAccount(name: string, apiKey: string, secretKey: string, encryptionKey: string) {
        return new Promise((resolve) => {
            this.sendCommand(Command.ADD_BINANCE_ACCOUNT, { name, apiKey, secretKey, encryptionKey }, resolve);
        });
    }

    public getTradingProgramsList() {
        this.sendCommand(Command.GET_TRADING_PROGRAMS_LIST);
    }

    public addTradingProgram(name: string, tradingProgram: string) {
        return new Promise((resolve) => {
            this.sendCommand(Command.ADD_TRADING_PROGRAM, { name, tradingProgram }, resolve);
        });
    }

    public testTP(tradingProgram: string) {
        return new Promise((resolve) => {
            this.sendCommand(Command.TEST_TRADING_PROGRAM, { tradingProgram }, resolve);
        });
    }

    public getTBReportsPerHour(tradingBotID: string) {
        return new Promise((resolve) => {
            this.sendCommand(Command.GET_TRADING_BOT_REPORTS_PER_DAY, { tradingBotID }, resolve);
        });
    }

    public getSymbolsList() {
        this.sendCommand(Command.GET_SYMBOLS);
    }

    public ping() {
        this.sendCommand(Command.PING);
    }
}
